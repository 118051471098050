import { connect } from "react-redux";
import { getProject } from '../../../redux/Projects/selector.js';
import { requestProject, uploadPhoto, deletePhoto, updateProject } from '../../../redux/Projects/action.js';

import AdminProjectContent from './AdminProjectContent';

const mapStateToProps = (state) => ({
	project: getProject(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestProject: projectTitle => dispatch(requestProject(projectTitle)),
	uploadPhoto: (projectTitle, base64String, imgType) => dispatch(uploadPhoto(projectTitle, base64String, imgType)),
	deletePhoto: (projectTitle, filename) => dispatch(deletePhoto(projectTitle, filename)),
	updateProject: (title, newTitle, base64String, description, imgType) =>
		dispatch(updateProject(title, newTitle, base64String, description, imgType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectContent);
