import {REQUEST_HOME_SUCCESS, UPDATE_HOME_SUCCESS} from './constant.js';

const initialState = {};
export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload.home
      };
	case UPDATE_HOME_SUCCESS:
	return {
	  ...state,
	  home: action.payload.home
	};
    default:
      return state;
  }
};
