import { AddProjectContainer, AddProjectTitle, AddProjectButton, ProjectCardViewContainer,
DropZoneContainer, ImageContainer} from './AddProjectForm.style.jsx'
import { useState, useEffect } from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {ProjectCardView} from '../ProjectCardView'
import Dropzone from 'react-dropzone'
import { Formik,  } from 'formik';

let blobFile;
let imgType;

const AddProjectForm = (props) => {
	const {handleChange, errors, dirty, values, createProject, projects} = props;
	const [imgtmp, setImgtmp] = useState<string>();

	const isButtonDisabled = 'title' in errors || !imgtmp || !dirty;

	const handleOnClick = () => {
		var reader = new FileReader();
	  reader.readAsDataURL(blobFile);

	  reader.onloadend = () => {
   			var base64String = reader.result;
			createProject(projects, values['title'], base64String, imgType);
			setImgtmp('');
			values['title'] = '';
		}
	}
	return (

		<AddProjectContainer>
		<form onSubmit={(e) => {e.preventDefault();}}>

 				<AddProjectTitle>
 				<input name={'title'} type='text' autoComplete="off" placeholder='Title' onChange={handleChange}
				value={values['title']}/>
 				</AddProjectTitle>
				<DropZoneContainer>

				<Dropzone
				accept={'image/jpeg, image/png, image/tiff, image/gif'}
				maxFiles={1}
				multiple={false}
				maxSize={31457280}
				onDropRejected={(rejectedFiles) => {}}
				onDropAccepted={(acceptedFiles) => {
					blobFile = new Blob(acceptedFiles);
					imgType = acceptedFiles[0].type.slice(6);}}
				onDrop={(acceptedFiles) => {acceptedFiles.length &&
				setImgtmp(URL.createObjectURL(acceptedFiles[0]))}}>
				  {({getRootProps, getInputProps}) => (
					<section>
					  <div {...getRootProps()}>
						<input {...getInputProps()} />
						<p>Drag 'n' drop some files here, or click to select files</p>
					  </div>
					</section>
				  )}
				</Dropzone>
				</DropZoneContainer>
				<ImageContainer>
					<img src={imgtmp} />
				</ImageContainer>

	 				<AddProjectButton type="button" disabled={isButtonDisabled} onClick={handleOnClick}>
	 						Valider
	 				</AddProjectButton>
				</form>

				</AddProjectContainer>


	);
};

export default AddProjectForm;
