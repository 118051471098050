import { Container, DescriptionContainer, LineContainer, ImageContainer, FooterContainer} from './Home.style.jsx';
import { useEffect } from 'react';
import { COPYRIGHT } from '../../dico';

const Home = (props) => {
	const {home, requestHome} = props;
	useEffect(() => {
		requestHome();
	}, []);
	return (
		<Container>
			<DescriptionContainer>
			{
				home && home.description.split("\n").map((i,key) => {
					return <LineContainer key={key}>{i}</LineContainer>;
				})
			}
		</DescriptionContainer>
		<ImageContainer>
		{home &&
			<img src={home.path + home.filename} />
		}
		</ImageContainer>
		<FooterContainer>
			{COPYRIGHT}
		</FooterContainer>
		</Container>
	);
};

export default Home;
