import { Container, LinkContainer, ProjectsContainer, ProjectTitle} from './ProjectsPage.style.jsx'
import { FooterContainer } from '../Home/Home.style.jsx'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Link} from "react-router-dom";
import { useEffect } from 'react';
import { COPYRIGHT } from '../../dico';
import {DESKTOP_MIN_WIDTH} from '../../style.js';

const ProjectsPage = (props) => {
	const {requestProjects, projects} = props;
	const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH);

	useEffect(() => {
		requestProjects();
	}, []);

	return (
		<Container isDesktop={isDesktop}>
		<ProjectsContainer>

			{projects && projects.map((project, i) => {
				return (
					<LinkContainer to={
						{
							pathname:"/projects/" + project.title,
							state: {
								data: project.photos
							}
						}}  >
						<ProjectTitle key={i}>
						{
							project.title
						}
						</ProjectTitle>
					</LinkContainer>
				)
			})}

		
	   </ProjectsContainer>
	   <FooterContainer>
		   {COPYRIGHT}
	   </FooterContainer>
		</Container>
	);
};

export default ProjectsPage;
