import styled from 'styled-components'
import { Link} from "react-router-dom";
import {FONT_FAMILY,} from '../../style'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 90%;
	margin-top: 75px;
	margin-left: ${props => props.isDesktop ? '75px' : '20px'};
	margin-right: ${props => props.isDesktop ? '75px' : '20px'};
	min-width: 200px;
	a:hover{
		cursor: pointer;
	}
`;

export const LinkContainer = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: start;
	text-decoration: none;
	color: black;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
	font-size: 16px;
	text-align: start;
	margin-bottom: 10px
`;

export const ProjectsContainer = styled.div`
	flex-grow: 1;
`
export const ProjectTitle = styled.div`
	margin-top: 10px;
`
