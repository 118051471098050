import styled from 'styled-components'

export const AddProjectContainer = styled.div`
	border: solid 1px black;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: 10px;
`;

export const AddProjectTitle = styled.div`
margin-bottom: 10px;
`;

export const AddProjectButton = styled.button`
	border-radius: 5px;
	border: 1px solid black;
	background-color: white;
	height: 30px;
	width: 100px;
	:disabled{
		border: 1px solid gray;
	}
	:hover:enabled{
		background-color: black;
		color: white;
		cursor: pointer;

	}
`;

export const ImageContainer = styled.div`
display: flex;
height: 100px;
margin-bottom: 10px;
`;
export const ProjectCardViewContainer = styled.div`
	div p:first-of-type :not(:first-child) {
		margin-top: 10px;
	}
`;

export const DropZoneContainer = styled.div`
	margin-bottom: 10px;
	border-color: #eeeeee;
	border-style: dashed;
	border-width: 2px;
	background-color: #fafafa;
	color: #bdbdbd;
	div{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
