import { Container} from './ChangePasswordPage.style.jsx'
import {ChangePasswordForm} from './ChangePasswordForm';
import * as Yup from 'yup';
import { Formik,  } from 'formik';

const PasswordSchema = Yup.object().shape({
	'oldPassword': Yup.string().required(),
	'newPassword': Yup.string().required(),
	'newPasswordConfirm': Yup.string().required(),
});

const ChangePasswordPage = (props) => {
	const {changePassword} = props;

	return (

		<Container>
			<Formik
			validationSchema={PasswordSchema}
			initialValues={{ oldPassword: '', newPassword: '', newPasswordConfirm: ''}}
			onSubmit={() => {}}
			>
			{({...formikProps}) => (
				<ChangePasswordForm {...formikProps} changePassword={changePassword}/>
			)}
			</Formik>
		</Container>


	);
};

export default ChangePasswordPage;
