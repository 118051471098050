import { connect } from "react-redux";
import { getProjects } from '../../../redux/Projects/selector.js';
import { requestProjects, updateProjects, deleteProject } from '../../../redux/Projects/action.js';

import AdminProjectsPage from './AdminProjectsPage';

const mapStateToProps = (state) => ({
	projects: getProjects(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestProjects: () => dispatch(requestProjects()),
	deleteProject: title => dispatch(deleteProject(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectsPage);
