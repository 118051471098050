export const COLORS = {
    BLACK: '#000000',
	WHITE: '#FFFFFF',
};

export const FONT_SIZE = {
};

export const FONT_FAMILY = {
    MOON: '\'Moon 2.0 Regular\'',
    CALIBRI: 'Calibri',
	OMNES_PRO: 'omnes-pro',
	GEORGIA: 'Georgia',
	EB_GARAMOND: 'EB Garamond',
	GARAMOND: 'Garamond',
	CORMORANT_GARAMOND: 'Cormorant Garamond'
};

export const DESKTOP_MIN_WIDTH = '(min-width:800px)';
