import { connect } from "react-redux";
import { requestToken, } from '../../../redux/Admin/action.js';

import LoginPage from './LoginPage';

const mapDispatchToProps = (dispatch) => ({
	requestToken: password => dispatch(requestToken(password)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
