import { LinkContainer, ImgContainer, TitleContainer, IconContainer, ContentContainer, PropertiesContainer,
InfoContainer} from './ProjectCardView.style.jsx'
import DeleteIcon from './../../../assets/icons/DeleteIcon.js';

const ProjectCardView = (props) => {
	const {project, deleteProject} = props;
	return (
		<LinkContainer to={
			{
				pathname:"/admin/projects/" + project.title,
				state: {
					data: project
				}
			}}  >
			<ImgContainer>
				<img src={project.path + project.filename}/>
			</ImgContainer>
			<ContentContainer>
				<InfoContainer>
					<TitleContainer>
						{project.title}
					</TitleContainer>
					<PropertiesContainer>
					{
						project.photos.length > 1 ? `${project.photos.length} photos` : `${project.photos.length} photo`
					}
					</PropertiesContainer>
				</InfoContainer>
				<IconContainer onClick={deleteProject}>
					<DeleteIcon/>
				</IconContainer>
			</ContentContainer>
		</LinkContainer>
	);
};

export default ProjectCardView;
