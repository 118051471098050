import { connect } from "react-redux";
import { requestProjects } from '../../redux/Projects/action.js';
import { getProjects } from '../../redux/Projects/selector.js';

import MainSwitch from './MainSwitch';

const mapStateToProps = (state) => ({
	projects: getProjects(state)
});

const mapDispatchToProps = (dispatch) => ({
   requestProjects: () => dispatch(requestProjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSwitch);
