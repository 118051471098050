import { connect } from "react-redux";
import { getAbout } from '../../../redux/About/selector.js';
import { requestAbout, updateAbout} from '../../../redux/About/action.js';

import AdminAbout from './AdminAbout';

const mapStateToProps = (state) => ({
	about: getAbout(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestAbout: () => dispatch(requestAbout()),
  updateAbout: (description) => dispatch(updateAbout(description)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAbout);
