import { connect } from "react-redux";
import { changePassword } from '../../../redux/Admin/action.js';

import ChangePasswordPage from './ChangePasswordPage';

const mapDispatchToProps = (dispatch) => ({
	changePassword: (oldPassword, newPassword, newPasswordConfirm) => dispatch(changePassword(oldPassword, newPassword, newPasswordConfirm)),
});

export default connect(null, mapDispatchToProps)(ChangePasswordPage);
