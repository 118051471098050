import { Container, InputDiv,  ProjectContainer,
IconContainer, LineSeparator, FormContainer,
DescriptionTitleContainer,
DescriptionContainer,} from './AdminAbout.style.jsx'
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import EditIcon from './../../../assets/icons/EditIcon.js';
import SaveIcon from './../../../assets/icons/SaveIcon.js';

const AdminAbout = (props) => {
	const {about, requestAbout, updateAbout} = props;
	const [editDescription, setEditDescription] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');


	const handleOnClick = (newTitle) => {
	};

	useEffect(() => {
		requestAbout();
	}, []);

	return (
		<Container>
		{
			about &&
			<ProjectContainer>
			<DescriptionTitleContainer>
				<p>Description</p>
				{!editDescription &&
					<IconContainer onClick={() => {setEditDescription(true)}}>
						<EditIcon/>
					</IconContainer>
				}
			</DescriptionTitleContainer>
			{
				editDescription ? (
					<Formik
					initialValues={{ description: about.description}}
					onSubmit={()=>{}}
					>
						{props => (
							<FormContainer onSubmit={(e) => {e.preventDefault();}}>
								<InputDiv maxLength={'10000'} name={'description'} type='text' autoComplete="off" onChange={props.handleChange}
								value={props.values.description}/>
								<IconContainer onClick={() => {
									setEditDescription(false);
									updateAbout(props.values.description);
								}}>
								<SaveIcon/>
							</IconContainer>
							</FormContainer>
					)}
					</Formik>
				) : (
					<>
						<LineSeparator/>
						<DescriptionContainer>{about.description}</DescriptionContainer>
						<LineSeparator/>

					</>
				)
			}

		</ProjectContainer>
	}
		</Container>
	);
};

export default AdminAbout;
