import {REQUEST_TOKEN, REQUEST_TOKEN_SUCCESS, REQUEST_TOKEN_FAILURE,
VERIFY_TOKEN, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAILURE,
CHANGE_PASSWORD} from './constant.js';

export const requestToken = (password) => ({
   type: REQUEST_TOKEN,
   payload: {password}
});

export const requestTokenSuccess = (token) => ({
   type: REQUEST_TOKEN_SUCCESS,
   payload: {token}
});

export const requestTokenFailure = () => ({
   type: REQUEST_TOKEN_FAILURE,
});

export const verifyToken = () => ({
   type: VERIFY_TOKEN,
});

export const verifyTokenSuccess = (token) => ({
   type: VERIFY_TOKEN_SUCCESS,
   payload: {token}
});

export const verifyTokenFailure = () => ({
   type: VERIFY_TOKEN_FAILURE,
});

export const changePassword = (oldPassword, newPassword, newPasswordConfirm) => ({
   type: CHANGE_PASSWORD,
   payload: {oldPassword, newPassword, newPasswordConfirm}
});
