import { connect } from "react-redux";
import { getProject, getNextProjectTitle, getPrevProjectTitle } from '../../redux/Projects/selector.js';
import { requestProject } from '../../redux/Projects/action.js';

import ProjectContent from './ProjectContent';

const mapStateToProps = (state) => ({
	project: getProject(state),
	next: getNextProjectTitle(state),
	prev: getPrevProjectTitle(state),
});

const mapDispatchToProps = (dispatch) => ({
  requestProject: projectTitle => dispatch(requestProject(projectTitle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContent);
