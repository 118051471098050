import React from 'react';
import './App.css';
import {MainSwitch} from './../MainSwitch';
import {Header} from './../Header';
import {Menu} from './../Menu';
import { BodyContainer, Container} from './App.style.jsx'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {DESKTOP_MIN_WIDTH} from '../../style.js';

const App = (props) => {
	const {displayMenu} = props;
	const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH);
  	return (
	  <Container>
	  	<Header/>
		<BodyContainer>
		{
			(isDesktop || !displayMenu) &&
	    	<MainSwitch/>
		}
		{
			!isDesktop && displayMenu &&
			<Menu/>
		}
		</BodyContainer>
	</Container>
  );
}

export default App;
