import { call, put, takeLatest } from "redux-saga/effects";
import {REQUEST_HOME, UPDATE_HOME} from './constant.js';
import {requestHomeSuccess, updateHomeSuccess} from './action.js'
import { queryGet, queryPut } from "../Api.js";
import {replace} from 'connected-react-router';
import {STORAGE_KEY, PATH_LOGIN_REPLACE} from '../constant.js';
const PATH_HOME = "home";


export function* requestHomeSaga() {
	try {
		const response = yield call(queryGet, PATH_HOME);
		yield put(requestHomeSuccess(response.data));
	} catch(e) {
	}
}

export function* updateHomeSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const description = action.payload.description;
		const base64String = action.payload.base64String;
		const imgType = action.payload.imgType;
		const response = yield call(queryPut, PATH_HOME, {base64String, description, token, imgType});
		yield put(updateHomeSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}


export function* homeSaga() {
  yield takeLatest(REQUEST_HOME, requestHomeSaga);
  yield takeLatest(UPDATE_HOME, updateHomeSaga);
}
