import { Container, MenuItemContainer, LogoContainer} from './Menu.style.jsx'
import InstaLogo from './../../assets/icons/InstaLogo.js';

const Menu = (props) => {
	const {setDisplayMenu} = props;
	const handleOnClick = () => {
		setDisplayMenu(false);
	};
	return (
		<Container>
			<MenuItemContainer>
			<a href="#home" onClick={handleOnClick}>home</a>
			<a href="#projects" onClick={handleOnClick}>photography</a>
			<a href="#about" onClick={handleOnClick}>about</a>
			<a href="#contact" onClick={handleOnClick}>contact</a>
			</MenuItemContainer>
			<LogoContainer>
				<InstaLogo/>
			</LogoContainer>
		</Container>
	);
};

export default Menu;
