import styled from 'styled-components'
import {FONT_FAMILY, COLORS} from '../../style'

export const Container = styled.div`
	background-color: ${COLORS.WHITE};
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;


export const MenuItemContainer = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-shrink: 0;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
	a{
		text-decoration: none;
		font-size: 8vmin;
		color: ${COLORS.BLACK};

	}
	a:not(:first-child) {
		margin-top: 30px;
	}
`;

export const LogoContainer = styled.div`
	display: none;
	margin-bottom: 15px;
`;
