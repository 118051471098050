import {REQUEST_PROJECTS, REQUEST_PROJECTS_SUCCESS, REQUEST_PROJECTS_FAILURE,
UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE, CREATE_PROJECT_SUCCESS,
DELETE_PROJECT_SUCCESS, REQUEST_PROJECT, REQUEST_PROJECT_SUCCESS, REQUEST_PROJECT_FAILURE,
UPLOAD_PHOTO_SUCCESS, DELETE_PHOTO_SUCCESS} from './constant.js';

const initialState = {
	projects: []
};
export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PROJECTS://TODO A supprimer?
      return {
        ...state,
      };

    case REQUEST_PROJECTS_SUCCESS:
      return {
        ...state,
		projects: action.payload.projects
      };

    case REQUEST_PROJECTS_FAILURE:
      return {
        ...state,
      };

	  case UPDATE_PROJECT_SUCCESS:
	    return {
	      ...state,
		  project: action.payload.project
	  };

	  case UPDATE_PROJECT_FAILURE:
	    return {
	      ...state,
	  };

	case CREATE_PROJECT_SUCCESS:
  	    return {
  	      ...state,
		  projects: action.payload.allProjects
	  };

	case DELETE_PROJECT_SUCCESS:
		return {
		  ...state,
		  projects: action.payload.allProjects
	};
	case REQUEST_PROJECT:
	  return {
		...state,
		project: undefined
	  };
	case REQUEST_PROJECT_SUCCESS:
	  return {
		...state,
		prev: action.payload.projectResponse.prev,
		next: action.payload.projectResponse.next,
		project: action.payload.projectResponse.project
	  };
	  case REQUEST_PROJECT_FAILURE:
  	  return {
  		...state,
  		project: undefined
  	  };
	  case UPLOAD_PHOTO_SUCCESS:
  	  return {
  		...state,
  		project: action.payload.project
  	  };
	  case DELETE_PHOTO_SUCCESS:
		  return {
			...state,
			project: action.payload.project
		  };

    default:
      return state;
  }
};
