import styled from 'styled-components'

export const FormContainer = styled.form`
	border: solid 1px black;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const PasswordContainer = styled.div`
margin-bottom: 10px;
`;

export const LoginButton = styled.button`
	border-radius: 5px;
	border: 1px solid black;
	background-color: white;
	height: 30px;
	width: 100px;
	:disabled{
		border: 1px solid gray;
	}
	:hover:enabled{
		background-color: black;
		color: white;
		cursor: pointer;

	}
`;
