import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers';
import {createHashHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

export const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer,
	applyMiddleware(
		routerMiddleware(history),
		sagaMiddleware
	)
);

sagaMiddleware.run(rootSaga);

export default store;
