import { LinkContainer, } from './MenuItem.style.jsx'
import PropTypes from 'prop-types';

const MenuItem = (props) => {
	const {link, itemName, currentPath} = props;
	const isActivePage = () => {
		return +currentPath.includes(itemName.toLowerCase());
	}
	return (
		<LinkContainer isactive={isActivePage()} to={link}  >
			{itemName}
		</LinkContainer>
	);
};

MenuItem.propTypes = {
	link: PropTypes.string.isRequired,
	itemName: PropTypes.string.isRequired,
};

export default MenuItem;
