import { FormContainer, PasswordContainer, LoginButton} from './LoginForm.style.jsx'

const LoginForm = (props) => {
	const {handleChange, errors, dirty, values, requestToken} = props;

	const isButtonDisabled = 'password' in errors || !dirty;

	const handleOnClick = () => {
		requestToken(values['password']);
	};
	return (

		<FormContainer onSubmit={(e) => {e.preventDefault();}}>

 				<PasswordContainer>
 					<input name={'password'} type='password' placeholder='Password' onChange={handleChange}
					value={values['password']}/>
 				</PasswordContainer>

	 				<LoginButton type="button" disabled={isButtonDisabled} onClick={handleOnClick}>
	 						Log in
	 				</LoginButton>
		</FormContainer>


	);
};

export default LoginForm;
