import styled from 'styled-components'

export const Container = styled.div`
	margin-top: 75px;
	margin-left: 75px;
	margin-right: 75px;
	img:hover{
		cursor: pointer;
	}
	display: flex;
	flex-direction: column;
`;


export const ProjectCardViewContainer = styled.div`
	margin-top: 10px;
`;
