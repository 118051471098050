import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `https://laetitiadeleuze.com:443/api/`
});

export const queryGet = async (path) => {
  return axiosInstance.get(path);
};

export const queryPost = async (path, body) => {
  return axiosInstance.post(path, body)
  .catch(function (error) {
	  throw error.response.status;
	});
};

export const queryPut = async (path, body) => {
  return axiosInstance.put(path, body).catch(function (error) {
  	  throw error.response.status;
  	});
};


export const queryDelete = async (path, body) => {
  return axiosInstance.delete(path, { data: body })
  .catch(function (error) {
  	  throw error.response.status;
  	});
};
