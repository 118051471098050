import {REQUEST_HOME, REQUEST_HOME_SUCCESS, UPDATE_HOME, UPDATE_HOME_SUCCESS} from './constant.js';

export const requestHome = () => ({
   type: REQUEST_HOME,
});

export const requestHomeSuccess = home => ({
   type: REQUEST_HOME_SUCCESS,
   payload: {home}
});


export const updateHome = (base64String, description, imgType) => ({
   type: UPDATE_HOME,
   payload: {base64String, description, imgType}
});

export const updateHomeSuccess = home => ({
   type: UPDATE_HOME_SUCCESS,
   payload: {home}
});
