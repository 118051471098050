import { all } from 'redux-saga/effects'
import {projectsSaga} from './Projects/saga';
import {adminSaga} from './Admin/saga';
import {homeSaga} from './Home/saga';
import {aboutSaga} from './About/saga';



export default function* rootSaga() {
  yield all([
	  projectsSaga(),
	  adminSaga(),
	  homeSaga(),
    aboutSaga(),
  ])
}
