import styled from 'styled-components'
import { FONT_FAMILY } from '../../style'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.isDesktop ? '50px' : '20px'};
	margin-right: 20px;
	align-items: start;
	font-size: 16px;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};

	margin-top: 100px;
	padding-bottom: 50px;

`;

export const LineContainer = styled.p`
	margin-top: 0px;
	margin-bottom: 0px;
	min-height: 18px;
	${props => props.alignRight ? 'align-self: end' : ''};

`;

export const Poeme = styled.div`
	display: flex;
	flex-direction: column;
	line-height: 27px;
`;

export const Bloc = styled.div`
	margin-top: 50px;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.div`
	font-weight: bold;
	margin-bottom: 20px;
`;

export const Italic = styled.span`
	font-style: italic;
`;

export const Underline = styled.span`
	text-decoration: underline;
`;

export const HTMLLink = styled.a`
	color: inherit;
`;
