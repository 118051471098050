import { Container, LineContainer, Poeme, Bloc, Title, Italic, Underline, HTMLLink} from './About.style.jsx';
import { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {DESKTOP_MIN_WIDTH} from '../../style.js';

const About = (props) => {
	const {about, requestAbout} = props;
	const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH);
	useEffect(() => {
		requestAbout();
	}, []);
	return (
		// {
	    //     about && about.description.split("\n").map((i,key) => {
	    //             return <LineContainer key={key}>{i}</LineContainer>;
	    //     })
		// }
		<Container isDesktop={isDesktop}>

			{isDesktop ? (
			<Poeme>
				<LineContainer>Une trouée de lumière verse à travers un ciel sombre ou tombe sur un fragment de branchage.</LineContainer>
				<LineContainer>Floconne par transparence dans un jet d’eau. Parfois, on regarde bien en face le soleil et le </LineContainer>
				<LineContainer>contre-jour caresse des jambes qui pendent d’un manège. Des enfants, des silhouettes amphibis, </LineContainer>
				<LineContainer>des créatures vibrantes s’élancent, dansent, des profils découpés attendent le métro, des corps </LineContainer>
				<LineContainer>offerts aux nuages s’allongent sur le sable. Nous sommes dans les visions de Laëtitia Deleuze.</LineContainer>
				<LineContainer>Sous sa paupière on sillonne la terre, on se fait eau et métal, on fend les stridences de la ville, des </LineContainer>
				<LineContainer>espaces zébrés, on fait le saut d’une rive à l’autre d’un océan majeur. Intérieur jour au rideau, </LineContainer>
				<LineContainer>extérieur contre-nuit. On pourrait passer sa main sur toutes textures et qualités, tiède brûlure et </LineContainer>
				<LineContainer>rèche de la douceur, de la pierre à la feuille, à travers règnes où l’animal est le roi muet. Mais pas </LineContainer>
				<LineContainer>mutique.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>Traversant le monde de l’intime en humanité tendre, éclats de joie et peines ténues, mais aussi les</LineContainer>
				<LineContainer>aventures du collectif par la rencontre des corps et des regards, le temps d’une chorégraphie ou</LineContainer>
				<LineContainer>d’une manifestation, le monde que nous donne à voir Laëtitia Deleuze est un monde de passages </LineContainer>
				<LineContainer>et de percées. Aussi francs que délicats, les contrastes marqués dans la lumière qui donnent </LineContainer>
				<LineContainer>contour à des scènes parfois spectrales, sont consolés par le gros grain qui épaissit le temps en </LineContainer>
				<LineContainer>dilatant l’infime. On se prend alors à respirer l’image, on devient soi-même l’oiseau noir entre ciel </LineContainer>
				<LineContainer>et asphalte, la femme phalène dans les rochers, cette petite silhouette floue qui se fond dans la </LineContainer>
				<LineContainer> mer, et notre ombre est créature de pétales.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>Loin d’en être des illustrations mimétiques, les citations littéraires associées à certaines séries font</LineContainer>
				<LineContainer>dialogue et éclairage, au même titre que la lumière saisie puis travaillée dans les tirages. Michaux,</LineContainer>
				<LineContainer>parmi bien d’autres, rappelle que les corps ici prennent forme « d’une brume à une chair », </LineContainer>
				<LineContainer>comme sont « infinis les passages en pays Meidosem ». Cependant, le mondre onirique et </LineContainer>
				<LineContainer>visionnaire de Laëtitia Deleuze ne se prive d’aucun enracinement réaliste et ne cherche pas la </LineContainer>
				<LineContainer>lutte, tout en s’accordant toutes les échappées. « Pas d’accord pour le dur, pas d’accord pour les </LineContainer>
				<LineContainer>larmes. Pas d’accord », dit aussi Michaux, qui pourtant ne renonce pas à nous les faire toucher de </LineContainer>
				<LineContainer>près. On peut aussi penser à ces accords de Stéphane Bouquet, dont les voix multiples et liquides </LineContainer>
				<LineContainer>s’élancent depuis une antiquité en partage jusqu’à Nos amériques, à la recherche d’une conversation </LineContainer>
				<LineContainer>toujours à venir, avec l’autre et le monde : « Et moi aussi, secrètement, secrètement, je me dépose </LineContainer>
				<LineContainer>dans la bouche duveteuse des choses ».</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer></LineContainer>
				<LineContainer alignRight={true}>
					Frédérique Cosnier, Autrice
				</LineContainer>
				<LineContainer alignRight={true}>
					Pour Laëtitia Deleuze. En amitié vive.
				</LineContainer>
				<LineContainer alignRight={true}>
					04.09.2022
				</LineContainer>
			</Poeme>
			) : (
			<Poeme>
				<LineContainer>
					Une trouée de lumière verse à travers un ciel sombre ou tombe sur un fragment de branchage.
					Floconne par transparence dans un jet d’eau. Parfois, on regarde bien en face le soleil et le
					contre-jour caresse des jambes qui pendent d’un manège. Des enfants, des silhouettes amphibis,
					des créatures vibrantes s’élancent, dansent, des profils découpés attendent le métro, des corps
					offerts aux nuages s’allongent sur le sable. Nous sommes dans les visions de Laëtitia Deleuze.
					Sous sa paupière on sillonne la terre, on se fait eau et métal, on fend les stridences de la ville, des
					espaces zébrés, on fait le saut d’une rive à l’autre d’un océan majeur. Intérieur jour au rideau,
					extérieur contre-nuit. On pourrait passer sa main sur toutes textures et qualités, tiède brûlure et
					rèche de la douceur, de la pierre à la feuille, à travers règnes où l’animal est le roi muet. Mais pas
					mutique.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
					Traversant le monde de l’intime en humanité tendre, éclats de joie et peines ténues, mais aussi les
					aventures du collectif par la rencontre des corps et des regards, le temps d’une chorégraphie ou
					d’une manifestation, le monde que nous donne à voir Laëtitia Deleuze est un monde de passages
					et de percées. Aussi francs que délicats, les contrastes marqués dans la lumière qui donnent
					contour à des scènes parfois spectrales, sont consolés par le gros grain qui épaissit le temps en
					dilatant l’infime. On se prend alors à respirer l’image, on devient soi-même l’oiseau noir entre ciel
					et asphalte, la femme phalène dans les rochers, cette petite silhouette floue qui se fond dans la
					 mer, et notre ombre est créature de pétales.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
					 Loin d’en être des illustrations mimétiques, les citations littéraires associées à certaines séries font
					 dialogue et éclairage, au même titre que la lumière saisie puis travaillée dans les tirages. Michaux,
					 parmi bien d’autres, rappelle que les corps ici prennent forme « d’une brume à une chair »,
					 comme sont « infinis les passages en pays Meidosem ». Cependant, le mondre onirique et
					 visionnaire de Laëtitia Deleuze ne se prive d’aucun enracinement réaliste et ne cherche pas la
					 lutte, tout en s’accordant toutes les échappées. « Pas d’accord pour le dur, pas d’accord pour les
					 larmes. Pas d’accord », dit aussi Michaux, qui pourtant ne renonce pas à nous les faire toucher de
					 près. On peut aussi penser à ces accords de Stéphane Bouquet, dont les voix multiples et liquides
					 s’élancent depuis une antiquité en partage jusqu’à Nos amériques, à la recherche d’une conversation
					 toujours à venir, avec l’autre et le monde : « Et moi aussi, secrètement, secrètement, je me dépose
					 dans la bouche duveteuse des choses ».
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer></LineContainer>
				<LineContainer alignRight={true}>
					Frédérique Cosnier, Autrice
				</LineContainer>
				<LineContainer alignRight={true}>
					Pour Laëtitia Deleuze. En amitié vive.
				</LineContainer>
				<LineContainer alignRight={true}>
					04.09.2022
				</LineContainer>
				</Poeme>)
			}
			<Bloc>
				<Title>
					Research & Art publications
				</Title>

				<LineContainer>
					2023 - Article "Relier, délier. Poétique de la présence et de l'attention", in <Italic>No</Italic> edited by Collektor Editions  for the second edition of <Italic>Réflexivité(s)</Italic>, a photographic manifestation taking place in Lourmarin from June 16th to July 30th 2023.
				</LineContainer>
				<LineContainer>
					<HTMLLink href="https://www.reflexivites.com/manifestation">https://www.reflexivites.com/manifestation</HTMLLink>
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
					 “Être(s) à l’écoute, être(s) au monde : Accueil des voix, porosité et sensorialité du récit dans les œuvres de Marie Cosnay et de Laurent Mauvignier”, <Italic>Revue Critique de Fixxion Française Contemporaine</Italic>, n°25 “Modes d’autorisation du récit contemporain”, under the direction of Jonas Hock, Estelle Mouton-Rovira and Kai Nonnenmacher, Ghent University, 2022.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
					Comme une image impossible venue brouiller le réel : Images et voix en résonance dans quelques textes de Laurent Mauvignier
					(Des hommes, Dans la foule, Ce que j’appelle oubli). “L’image incertaine. Pluralité de l’image dans l’œuvre de Laurent Mauvignier”,
					directed by Sylvie Loignon, Presses Sorbonne Nouvelle, Paris, 2022.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
				Suivre le fil de la blessure : Poétique des voix et corporalité de la mémoire dans Loin d’eux de Laurent Mauvignier et Thésée, sa vie
				nouvelle de Camille de Toledo. Cahiers ERTA Numéro 31 “L'Oubli”, directed by Ewa M. Wierzbowska, University of Gdansk Publishing, Poland, 2022.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
				Écrire la frontière. Co-direction with Johanna Carvajal González & Mounir Taïri. MaLiCE, le Magazine des
				Littératures et des Cultures à l'ère numérique, Aix-Marseille Université, 2022.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
				“Le texte est du côté de la tentative — comme la colère, celle-ci peut être désespérée, mais relève tout de même d’un flux vital.”
				Marina Skalova - auteure, poétesse et dramaturge et Laëtitia Deleuze - Aix-Marseille Université, CIELAM (Entretien). MaLiCE, le Magazine
				des Littératures et des Cultures à l'ère numérique, Aix-Marseille Université, 2022.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>
				“Un tout autre Sartre” de François Noudelmann. Entretien entre François Noudelmann et Laetitia Deleuze.
				L'ARC, Department of French Literature, Thought and Culture, New York University Volume 34, Fall 2020.
				</LineContainer>
			</Bloc>
			<Bloc>
				<Title>
					Writing publications
				</Title>
				<LineContainer>
					2023 - <Italic>Bernie</Italic> N*4 (Zine) "Equinoxe de printemps", Poem & Photographs, direction by Florence Andoka & Quentin Bob Mercier.
				</LineContainer>
			</Bloc>
			<Bloc>
				<Title>
					Photographic Publications
				</Title>
				<LineContainer>2022 - <Italic>Bernie</Italic> N*3 (Zine), collaboration with the writer Frédérique Cosnier, direction by Florence Andoka & Quentin Bob Mercier.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>2022 - <Italic>Point de Chute</Italic> N*5, literary and poetic review, cover photo, direction by Stéphane Lambion, Victor Malzac & Joep Polderman.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>2021 - <Italic>“2020”</Italic> group zine. Duck Sale Press, UK.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>2020 - <Italic>Les Impromptus</Italic>, Volume 3. Artistic and Literary Review. Collaborative work with the poet Ecriture Silex, France.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>2017 - <Italic>CARRETE</Italic> Volume 1. Revista especializada en fotographia analogica, Spain.</LineContainer>
			</Bloc>

			<Bloc>
				<Title>
					Exhibitions
				</Title>
				<LineContainer>2020 - <Italic>PaperGirl</Italic> public art project Exhibition. Brooklyn, New York.</LineContainer>
				<LineContainer>
					Papergirl-Brooklyn is a participatory public art project bringing an interactive art experience to various communities around
					Brooklyn, centering resident voices, and redistributing contributed artwork to the unsuspecting public.
				</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>2016 - <Italic>Clay des songes</Italic>. Marseille, France.</LineContainer>
				<LineContainer>Medium format film project with the ceramic atelier “De la terre à la lune”, located in Marseille.</LineContainer>
			</Bloc>

			<Bloc>
				<Title>
					Research Seminar
				</Title>
				<LineContainer>“Traverser les frontières: Formes et enjeux des écritures contemporaines”, Aix-Marseille University. 2021-2022.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>Organizer and moderator of the following sessions:</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>“Traverser les frontières dans la littérature d’expression française : Écrire d’un versant d’une langue vers l’abri noir de l’autre”.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>Guest speaker: Professor André Benhaïm (Princeton University)</LineContainer>
				<LineContainer>January 10th, 2022</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>“Marginalia. Récits de voix et d’écoute dans la littérature d’expression française contemporaine : Autour de l’œuvre d’Arno Bertina”.</LineContainer>
				<LineContainer></LineContainer>
				<LineContainer>Guest speaker: Arno Bertina (Writer)</LineContainer>
				<LineContainer>May 17th, 2022</LineContainer>
			</Bloc>

			<Bloc>
				<Title>
					Collaborating Artists
				</Title>
				<LineContainer>Maho Annpann - Dancer</LineContainer>
				<LineContainer>Elodie Auger - Dancer</LineContainer>
				<LineContainer>Daina Ashbee - Choreographer</LineContainer>
				<LineContainer>Sunny Bjaanes - Choreographer, Dancer</LineContainer>
				<LineContainer>Ecriture Silex - Poet</LineContainer>
				<LineContainer>Alexis Evans Krueger -Dancer</LineContainer>
				<LineContainer>Daniele Di Felice - Dancer</LineContainer>
				<LineContainer>Felix Bryan - Dancer</LineContainer>
				<LineContainer>Julie Magneville - Choreographer, Dancer</LineContainer>
				<LineContainer>Katherine Maxwell - Choreographer, Hivewild</LineContainer>
				<LineContainer>Gabriel Nieto - Dancer</LineContainer>
				<LineContainer>Liana Kleinman - Dancer</LineContainer>
				<LineContainer>Richard Pop - Choreographer, Dancer</LineContainer>
				<LineContainer>Zachary Richardson - Dancer</LineContainer>
				<LineContainer>Frédérique Cosnier - Writer</LineContainer>
			</Bloc>
		</Container>
	);
};

export default About;
