import styled from 'styled-components'

export const BodyContainer = styled.div`
	position: relative;
	height: 100%;

`;

export const Container = styled.div`
display: flex;
flex-direction: column;
height: 100%;
`;
