import {REQUEST_TOKEN, REQUEST_TOKEN_SUCCESS, REQUEST_TOKEN_FAILURE,
VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAILURE} from './constant.js';

const initialState = {
	projects: []
};
export const adminReducer = (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_TOKEN_SUCCESS:
      return {
        ...state,
		token: action.payload.token
      };

    case REQUEST_TOKEN_FAILURE:
      return {
        ...state,
		token: undefined
      };
	  case VERIFY_TOKEN_SUCCESS:
        return {
          ...state,
  		token: action.payload.token
        };

      case VERIFY_TOKEN_FAILURE:
        return {
          ...state,
  		token: undefined
        };

    default:
      return state;
  }
};
