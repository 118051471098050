import { combineReducers } from 'redux';
import { menuReducer} from './Menu/reducer';
import { projectsReducer } from './Projects/reducer';
import { adminReducer } from './Admin/reducer';
import { homeReducer } from './Home/reducer';
import { aboutReducer } from './About/reducer';

import {createHashHistory} from 'history';
import {connectRouter} from 'connected-react-router';

export const history = createHashHistory();

const rootReducer = combineReducers({
	menuReducer,
	projectsReducer,
	adminReducer,
	homeReducer,
	aboutReducer,
	router: connectRouter(history)
});

export default rootReducer;
