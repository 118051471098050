import { connect } from "react-redux";
import { createProject } from '../../../redux/Projects/action.js';
import { getProjects } from '../../../redux/Projects/selector.js';

import AddProjectForm from './AddProjectForm';

const mapStateToProps = (state) => ({
	projects: getProjects(state)
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (allProjects, title, base64String, imgType) => dispatch(createProject(allProjects, title, base64String, imgType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectForm);
