export const getProjects = (state) => {
    return state && state.projectsReducer && state.projectsReducer.projects;
};
export const getProject = (state) => {
    return state && state.projectsReducer && state.projectsReducer.project;
};
export const getNextProjectTitle = (state) => {
    return state && state.projectsReducer && state.projectsReducer.next;
};
export const getPrevProjectTitle = (state) => {
    return state && state.projectsReducer && state.projectsReducer.prev;
};
