import { Container, TitleContainer, DescriptionContainer, TitleAndDescriptionContainer,
GalleryContainer, LineContainer, NextAndPrevContainer, LinkContainer} from './ProjectContent.style.jsx'
import { FooterContainer } from '../Home/Home.style.jsx'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState, useEffect } from 'react';
import { COPYRIGHT } from '../../dico';
import {DESKTOP_MIN_WIDTH} from '../../style.js';

const ProjectContent = (props) => {
	const {project, requestProject, prev, next} = props;
	const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH);

	useEffect(() => {
		const projectTitle = props.match.params.id;
		requestProject(projectTitle);
	}, [props.match.params.id]);

	return (
		<>
			{
				project && (
			<Container>
				<TitleAndDescriptionContainer>
					<TitleContainer>
						{project.title}
					</TitleContainer>
					<DescriptionContainer>
					{
						project.description.split("\n").map((i,key) => {
		            		return <LineContainer key={key}>{i}</LineContainer>;
		        		})
					}
					</DescriptionContainer>
				</TitleAndDescriptionContainer>
				<GalleryContainer isDesktop={isDesktop}>
					<ResponsiveMasonry
							  columnsCountBreakPoints={{350: 1, 600: 2, 800: 3}}
						  >
					   <Masonry gutter="40px">
							{project.photos.map((item, i) => {
								return (
									<div key={i}>
										<img src={item.path + item.filename} style={{width: "100%", display: "block"}} />
									</div>
								)
							})}
					   </Masonry>
				   </ResponsiveMasonry>
			   </GalleryContainer>
			   <NextAndPrevContainer>
				{ prev && (
			   		<LinkContainer to={
					   {
						   pathname:"/projects/" + prev,
					   }}>Previous project</LinkContainer>
					)
				}
				{next && (

				   <LinkContainer to={
					   {
						   pathname:"/projects/" + next,
					   }}>Next project</LinkContainer>
					)
				}
			   </NextAndPrevContainer>
			   <FooterContainer>
				   {COPYRIGHT}
			   </FooterContainer>
		   </Container>

	   )}
	   </>
	);
};

export default ProjectContent;
