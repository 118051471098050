import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	margin-right: 20px;
	align-items: center;
`;

export const DescriptionContainer = styled.p`
	font-size: 15px;
	text-align: center;
	display: flex;
	flex-direction: column;
`;

export const LineContainer = styled.p`
	margin-top: 0px;
	margin-bottom: 0px;
	min-height: 18px;
`;

export const ImageContainer = styled.div`
	margin-top: -40px;
	margin-bottom: 0px;
	img{
		max-height: 1100px;
		max-width: 1100px;
	}
`;

export const FooterContainer = styled.div`
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
`
