import styled from 'styled-components'


export const FormContainer = styled.form`
	border: solid 1px black;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 200px;
`;

export const PasswordContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100px;
`;

export const ChangePasswordButton = styled.button`
	border-radius: 5px;
	border: 1px solid black;
	background-color: white;
	height: 40px;
	width: 100px;
	:disabled{
		border: 1px solid gray;
	}
	:hover:enabled{
		background-color: black;
		color: white;
		cursor: pointer;

	}
`;
