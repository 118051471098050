import { Container, } from './Contact.style.jsx'
import SvgInstaLogo from './../../assets/icons/InstaLogo.js';


const Contact = () => {
	return (
		<Container>
			<a href="mailto:deleuze.laetitia@gmail.com">deleuze.laetitia@gmail.com</a>
			<a href="https://instagram.com/laetitiatantelydeleuze"><SvgInstaLogo/></a>

			
		</Container>
	);
};

export default Contact;
