import { connect } from "react-redux";
import { getAbout } from '../../redux/About/selector.js';
import { requestAbout,} from '../../redux/About/action.js';

import About  from './About';

const mapStateToProps = (state) => ({
	about: getAbout(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestAbout: () => dispatch(requestAbout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
