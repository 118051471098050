import styled from 'styled-components'
import {FONT_FAMILY, COLORS} from '../../style'

export const Container = styled.div`
	font-size: 16px;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-top: 100px;
	a{
		color: ${COLORS.BLACK};
	}
`;
