import { connect } from "react-redux";

import AdminPrivateRoute from './AdminPrivateRoute';
import { verifyToken } from '../../../redux/Admin/action.js';
import { getToken } from '../../../redux/Admin/selector.js';

const mapStateToProps = (state) => ({
	token: getToken(state)
});

const mapDispatchToProps = (dispatch) => ({
  verifyToken: () => dispatch(verifyToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrivateRoute);
