import { Route, Switch, Redirect} from "react-router-dom";
import {Home} from './../Home';
import {About} from './../About';
import {ProjectsPage} from './../ProjectsPage';
import {ProjectContent} from './../ProjectContent';
import {PrivateRoute} from './../PrivateRoute';
import {AdminPrivateRoute} from './../Admin/AdminPrivateRoute';
import {LoginPrivateRoute} from './../Admin/LoginPrivateRoute';
import {AdminProjectsPage} from './../Admin/AdminProjectsPage';
import {AdminProjectContent} from './../Admin/AdminProjectContent';
import {AdminHome} from './../Admin/AdminHome';
import {AdminAbout} from './../Admin/AdminAbout';
import {ChangePasswordPage} from './../Admin/ChangePasswordPage';
import {LoginPage} from './../Admin/LoginPage';
import {Contact} from './../Contact';
import { useEffect } from 'react';

const MainSwitch = (props) => {
	const {requestProjects, projects} = props;

	useEffect(() => {
		requestProjects();
	}, []);
    return (
			<Switch>
			<Route exact path="/home" component={Home}/>
	          <Route exact path="/about" component={About}/>

			  <Route exact path="/contact" component={Contact}/>
			  <Route exact path="/projects" component={ProjectsPage}/>

			 <Route exact path="/projects/:id" component={ProjectContent}/>
			 <AdminPrivateRoute exact path="/admin/password" component={ChangePasswordPage}/>
			 <AdminPrivateRoute exact path="/admin/home" component={AdminHome}/>
			 <AdminPrivateRoute exact path="/admin/about" component={AdminAbout}/>

			 <AdminPrivateRoute exact path="/admin/projects" component={AdminProjectsPage}/>
			 <AdminPrivateRoute exact path="/admin/projects/:id" component={AdminProjectContent}/>

			 <LoginPrivateRoute exact path="/admin/login" component={LoginPage} redirectLink={'/admin/projects'}/>

			  <Redirect to='/home'/>
	        </Switch>
    );
};

export default MainSwitch;
