import {SET_DISPLAY_MENU, } from './constant.js';

const initialState = {};
export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISPLAY_MENU:
      return {
        ...state,
        displayMenu: action.payload.displayMenu
      };
    default:
      return state;
  }
};
