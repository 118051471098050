import {REQUEST_ABOUT, REQUEST_ABOUT_SUCCESS, UPDATE_ABOUT, UPDATE_ABOUT_SUCCESS} from './constant.js';

export const requestAbout = () => ({
   type: REQUEST_ABOUT,
});

export const requestAboutSuccess = about => ({
   type: REQUEST_ABOUT_SUCCESS,
   payload: {about}
});


export const updateAbout = description => ({
   type: UPDATE_ABOUT,
   payload: {description}
});

export const updateAboutSuccess = about => ({
   type: UPDATE_ABOUT_SUCCESS,
   payload: {about}
});
