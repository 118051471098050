import { Redirect, Route } from "react-router-dom";
import { useEffect } from 'react';

const LoginPrivateRoute = (props) => {
	const {computedMatch: match, component: Component, token, verifyToken, redirectLink : RedirectLink} = props;

	useEffect(() => {
		verifyToken();
	}, []);

	return (
		<Route
			render={ () => (
				!token ?
				<Component />
				: <Redirect to={RedirectLink}/>
				)
			}
		/>
	);
};

export default LoginPrivateRoute;
