import { call, put, takeLatest } from "redux-saga/effects";
import {REQUEST_TOKEN, VERIFY_TOKEN, CHANGE_PASSWORD} from './constant.js';
import {requestTokenSuccess, requestTokenFailure, verifyTokenSuccess, verifyTokenFailure} from './action.js'
import { queryGet, queryPost, queryPut, queryDelete } from "../Api.js";
import {replace} from 'connected-react-router';
import {STORAGE_KEY, PATH_LOGIN_REPLACE} from '../constant.js';

const PATH_ADMIN_PROJECTS = '/admin/projects';
const PATH_LOGIN = 'login';
const PATH_VERIFY = 'verify';
const PATH_PASSWORD = 'password';




export function* requestTokenSaga(action) {
	try {
		const password = action.payload.password;
		const response = yield call(queryPost, PATH_LOGIN, {password});
		requestTokenSuccess(response.data);
		sessionStorage.setItem(STORAGE_KEY, response.data);
		yield put(replace(PATH_ADMIN_PROJECTS));
	} catch(e) {
		sessionStorage.removeItem(STORAGE_KEY);
		yield put(requestTokenFailure());
	}
}

export function* verifyTokenSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(verifyTokenFailure());
			return;
		}
		const response = yield call(queryPost, PATH_VERIFY, {token});
		yield put(verifyTokenSuccess(response.data));

	} catch(e) {
		sessionStorage.removeItem(STORAGE_KEY);
		yield put(verifyTokenFailure());
	}
}

export function* changePasswordSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const oldPassword = action.payload.oldPassword;
		const newPassword = action.payload.newPassword;
		const newPasswordConfirm = action.payload.newPasswordConfirm;

		const response = yield call(queryPost, PATH_PASSWORD, {oldPassword, newPassword, newPasswordConfirm, token});
		yield put(replace(PATH_ADMIN_PROJECTS));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* adminSaga() {
	yield takeLatest(REQUEST_TOKEN, requestTokenSaga);
	yield takeLatest(VERIFY_TOKEN, verifyTokenSaga);
	yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}
