import styled from 'styled-components'
import { Link} from "react-router-dom";
import {FONT_FAMILY, COLORS} from '../../../style'

export const LinkContainer = styled(Link)`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	border-radius: 10px;
	max-width: 50%;
	text-decoration: none;
	color: ${COLORS.BLACK};
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
`;

export const ImgContainer = styled.div`

	img {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		height: 100%;
		width: 100%;
	}
`;


export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-right: solid 1px grey;
	border-top: solid 1px grey;
	border-bottom: solid 1px grey;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	width: 100%;
`;

export const InfoContainer = styled.div`
	margin-left: 30px;
	margin-top: 30px;

	display: flex;
	flex-direction: column;
`;

export const TitleContainer = styled.div`

	font-size: 30px;
`;

export const PropertiesContainer = styled.div`
`;



export const IconContainer = styled.div`
	align-self: flex-end;
	cursor: pointer;
	margin-right: 16px;
	margin-bottom: 16px;
`;
