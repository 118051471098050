import styled from 'styled-components';
import { Link} from "react-router-dom";
import { FONT_FAMILY } from '../../style'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	margin-right: 20px;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
`;

export const TitleContainer = styled.div`
	font-size: 16px;
	margin-bottom: 20px;
	font-weight: bold;
`;

export const DescriptionContainer = styled.p`
	font-size: 15px;
	text-align: center;
	display: flex;
	flex-direction: column;
`;

export const LineContainer = styled.p`
	margin-top: 0px;
	margin-bottom: 0px;
	min-height: 18px;
`;

export const TitleAndDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 800px;
	margin: auto;
	margin-bottom: 40px;
	margin-top: 40px;
`;

export const GalleryContainer = styled.div`
	margin-left: ${props => props.isDesktop ? '100px' : '20px'};
	margin-right: ${props => props.isDesktop ? '100px' : '20px'};
`;

export const NextAndPrevContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 16px;
	margin-top: 16px;
`;

export const LinkContainer = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: black;
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
	font-size: 16px;
	text-align: center;
`;
