export const REQUEST_PROJECTS = "REQUEST_PROJECTS";
export const REQUEST_PROJECTS_SUCCESS = "REQUEST_PROJECTS_SUCCESS";
export const REQUEST_PROJECTS_FAILURE = "REQUEST_PROJECTS_FAILURE";

export const REQUEST_PROJECT = "REQUEST_PROJECT";
export const REQUEST_PROJECT_SUCCESS = "REQUEST_PROJECT_SUCCESS";
export const REQUEST_PROJECT_FAILURE = "REQUEST_PROJECT_FAILURE";

export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE";

export const DELETE_PHOTO = "DELETE_PHOTO";
export const DELETE_PHOTO_SUCCESS = "DELETE_PHOTO_SUCCESS";
export const DELETE_PHOTO_FAILURE = "DELETE_PHOTO_FAILURE";
