import { connect } from "react-redux";
import { getDisplayMenu } from '../../redux/Menu/selector.js';
import {setDisplayMenu} from '../../redux/Menu/action.js';
import Header from './Header';

const mapStateToProps = (state) => ({
	displayMenu: getDisplayMenu(state)
});

const mapDispatchToProps = (dispatch) => ({
  setDisplayMenu: (displayMenu) => dispatch(setDisplayMenu(displayMenu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
