import {REQUEST_ABOUT_SUCCESS, UPDATE_ABOUT_SUCCESS} from './constant.js';

const initialState = {};
export const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ABOUT_SUCCESS:
      return {
        ...state,
        about: action.payload.about
      };
	case UPDATE_ABOUT_SUCCESS:
	return {
	  ...state,
	  about: action.payload.about
	};
    default:
      return state;
  }
};
