import styled from 'styled-components'
import {FONT_FAMILY, COLORS} from '../../style'

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 75px;
	margin-right: ${props => props.isDesktop ? '75px' : '30px'};
	padding-top: 24px;
	padding-bottom: 24px;
	font-size: 16px;
	a{
		text-decoration: none;
		color: ${COLORS.BLACK};
	}
	font-family: ${FONT_FAMILY.CORMORANT_GARAMOND};
`;


export const MenuItemContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	flex-shrink: 1;

	a{
		margin-right: 2rem;
	}
	a:hover{
		 border-bottom: 1px solid ${COLORS.BLACK};
	}
`;

export const HamburgerMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 35px;
	height: 35px;
	cursor: pointer;
`;

export const TopLine = styled.div`
	background-color: ${COLORS.BLACK};
	height: 1px;
	width: 25px;
	${props => props.displayMenu ? 'transform: translatey(7px) rotate(-135deg)' : ''};
`;

export const BottomLine = styled.div`
	background-color: ${COLORS.BLACK};
	height: 1px;
	width: 25px;
	margin-top: 0.8rem;
	${props => props.displayMenu ? 'transform: translatey(-6px) rotate(135deg)' : ''};
`;

export const IconContainer = styled.a`
	display: none;
`;
