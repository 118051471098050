import { connect } from "react-redux";
import { getProjects } from '../../redux/Projects/selector.js';
import { requestProjects, updateProjects } from '../../redux/Projects/action.js';

import ProjectsPage from './ProjectsPage';

const mapStateToProps = (state) => ({
	projects: getProjects(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestProjects: () => dispatch(requestProjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
