import { call, put, takeLatest } from "redux-saga/effects";
import {REQUEST_PROJECTS, DELETE_PROJECT, CREATE_PROJECT,
UPLOAD_PHOTO, UPLOAD_PHOTO_SUCCESS, UPDATE_PROJECT, REQUEST_PROJECT,
DELETE_PHOTO} from './constant.js';
import {requestProjectsSuccess, requestProjectsFailure,
	createProjectSuccess, deleteProjectSuccess, requestProjectSuccess, requestProjectFailure,
	uploadPhotoSuccess, deletePhotoSuccess, updateProjectSuccess} from './action.js'
import { queryGet, queryPost, queryPut, queryDelete } from "../Api.js";
import {replace} from 'connected-react-router';
import {STORAGE_KEY, PATH_LOGIN_REPLACE} from '../constant.js';
const PATH_PROJECTS = "projects";
const PATH_PHOTOS = "photos";
const PATH_PROJECT = "project";

export function* requestProjectsSaga(action) {
	try {
		const response = yield call(queryGet, PATH_PROJECTS);
	    yield put(requestProjectsSuccess(response.data));
	} catch(e) {
		yield put(requestProjectsFailure([]));
	}
}

export function* deleteProjectSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const title = action.payload.title;
		const index = action.payload.index;
		const response = yield call(queryDelete, PATH_PROJECTS, {title, token});
		yield put(deleteProjectSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* createProjectSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const title = action.payload.title;
		const base64String = action.payload.base64String;
		const imgType = action.payload.imgType;
		const allProjects = action.payload.allProjects;
		const response = yield call(queryPost, PATH_PROJECTS, {title, base64String, token, imgType});
		yield put(createProjectSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* uploadPhotoSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const projectTitle = action.payload.projectTitle;
		const base64String = action.payload.base64String;
		const imgType = action.payload.imgType;
		const response = yield call(queryPost, PATH_PHOTOS, {projectTitle, base64String, token, imgType});
		yield put(uploadPhotoSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* deletePhotoSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const projectTitle = action.payload.projectTitle;
		const filename = action.payload.filename;
		const response = yield call(queryDelete, PATH_PHOTOS, {projectTitle, filename, token});
		yield put(deletePhotoSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* updateProjectSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const title = action.payload.title;
		const newTitle = action.payload.newTitle;
		const base64String = action.payload.base64String;
		const description = action.payload.description;
		const imgType = action.payload.imgType;
		const response = yield call(queryPut, PATH_PROJECT, {title, newTitle, base64String, description, token, imgType});
		yield put(updateProjectSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}

export function* requestProjectSaga(action) {
	try {
		const projectTitle = action.payload.projectTitle;
		const response = yield call(queryGet, PATH_PROJECT + '/' + projectTitle);
		if (response.data.length == 1) {
			yield put(requestProjectSuccess(response.data[0]));
		} else {
			yield put(requestProjectFailure());
			yield put(replace('/' + PATH_PROJECTS));
		}
	} catch(e) {
		yield put(requestProjectFailure());
	}

}


export function* projectsSaga() {
  yield takeLatest(REQUEST_PROJECTS, requestProjectsSaga);
  yield takeLatest(DELETE_PROJECT, deleteProjectSaga);
  yield takeLatest(CREATE_PROJECT, createProjectSaga);
  yield takeLatest(UPLOAD_PHOTO, uploadPhotoSaga);
  yield takeLatest(UPDATE_PROJECT, updateProjectSaga);
  yield takeLatest(REQUEST_PROJECT, requestProjectSaga);
  yield takeLatest(DELETE_PHOTO, deletePhotoSaga);

}
