import { Redirect, Route } from "react-router-dom";
import { useEffect } from 'react';

const pathLogin = '/admin/login';

const AdminPrivateRoute = (props) => {
	const {computedMatch: match, component: Component, token, verifyToken, ...rest} = props;

	useEffect(() => {
		verifyToken();
	}, []);

	return (
		<Route {...rest}
			render={ (matchProps) => (
				token ?
				<Component {...matchProps}/>
				: <Redirect to={pathLogin}/>
				)
			}
		/>
	);
};

export default AdminPrivateRoute;
