import { Container} from './LoginPage.style.jsx';
import { Formik, Form, Field } from 'formik';
import {LoginForm} from './LoginForm';
import * as Yup from 'yup';

const PasswordSchema = Yup.object().shape({
	'password': Yup.string().required(),
});

const LoginPage = (props) => {
	const {requestToken} = props;

	return (

		<Container>
		<Formik
		validationSchema={PasswordSchema}
		initialValues={{ password: ''}}
		onSubmit={() => {}}
		>
		{({...formikProps}) => (
			<LoginForm {...formikProps} requestToken={requestToken}/>
		)}
		</Formik>
		</Container>
	);
};

export default LoginPage;
