import { connect } from "react-redux";
import { getHome } from '../../../redux/Home/selector.js';
import { requestHome, updateHome} from '../../../redux/Home/action.js';

import AdminHome from './AdminHome';

const mapStateToProps = (state) => ({
	home: getHome(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestHome: () => dispatch(requestHome()),
  updateHome: (base64String, description, imgType) => dispatch(updateHome(base64String, description, imgType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
