import { PasswordContainer, ChangePasswordButton, FormContainer} from './ChangePasswordForm.style.jsx'

const LoginForm = (props) => {
	const {handleChange, errors, dirty, values, changePassword} = props;

	const isButtonDisabled = 'oldPassword' in errors || 'newPassword' in errors || 'newPasswordConfirm' in errors || !dirty
	|| values['newPassword'] != values['newPasswordConfirm'];

	const handleOnClick = () => {
		changePassword(values['oldPassword'], values['newPassword'], values['newPasswordConfirm']);
	}
	return (

			<FormContainer onSubmit={(e) => {e.preventDefault();}}>

	 				<PasswordContainer>
					<input name={'oldPassword'} type='password' placeholder='Old password' onChange={handleChange}
						value={values['oldPassword']}/>
					<input name={'newPassword'} type='password' placeholder='New password' onChange={handleChange}
					value={values['newPassword']}/>
					<input name={'newPasswordConfirm'} type='password' placeholder='Confirm new password' onChange={handleChange}
					value={values['newPasswordConfirm']}/>
	 				</PasswordContainer>

	 				<ChangePasswordButton type="button" disabled={isButtonDisabled} onClick={handleOnClick}>
		 						Change password
	 				</ChangePasswordButton>
			</FormContainer>


	);
};

export default LoginForm;
