import { MenuItemContainer, TopLine, BottomLine, HamburgerMenuContainer, Container, IconContainer} from './Header.style.jsx'
import InstaLogo from './../../assets/icons/InstaLogo.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {MenuItem} from './../MenuItem';
import {DESKTOP_MIN_WIDTH} from '../../style.js';

const Header = (props) => {
	const {displayMenu, setDisplayMenu} = props;
	const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH);
	const handleOnClick = () => {
		setDisplayMenu(!displayMenu);
	}
	return (
		<Container isDesktop={isDesktop}>
			<a href="#home">LAETITIA DELEUZE</a>
			{
				isDesktop ? (
					<>
						<MenuItemContainer>
							<MenuItem link={'/home'} itemName={'home'}/>
							<MenuItem link={'/projects'} itemName={'photography'}/>
							<MenuItem link={'/about'} itemName={'about'}/>
							<MenuItem link={'/contact'} itemName={'contact'}/>
						</MenuItemContainer>
						<IconContainer href="#home">
							<InstaLogo/>
						</IconContainer>
					</>
				) : (
					<>
						<HamburgerMenuContainer onClick={handleOnClick}>
							<TopLine displayMenu={displayMenu}/>
							<BottomLine displayMenu={displayMenu}/>
						</HamburgerMenuContainer>
					</>
				)
			}
		</Container>
	);
};

export default Header;
