import { connect } from "react-redux";
import { getHome } from '../../redux/Home/selector.js';
import { requestHome,} from '../../redux/Home/action.js';

import Home from './Home';

const mapStateToProps = (state) => ({
	home: getHome(state)
});

const mapDispatchToProps = (dispatch) => ({
  requestHome: () => dispatch(requestHome()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
