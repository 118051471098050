import { Container, DropZoneContainer, InputDiv,  ProjectContainer,
IconContainer, LineSeparator, ImageContainer, FormContainer,
DescriptionTitleContainer,
DescriptionContainer} from './AdminHome.style.jsx'
import Dropzone from 'react-dropzone'
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import EditIcon from './../../../assets/icons/EditIcon.js';
import SaveIcon from './../../../assets/icons/SaveIcon.js';

let blobFile;
let imgType;

const AdminHome = (props) => {
	const {home, requestHome, updateHome} = props;
	const [editDescription, setEditDescription] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');


	useEffect(() => {
		requestHome();
	}, []);

	return (
		<Container>
		{
			home &&
			<ProjectContainer>
			<DescriptionTitleContainer>
				<p>Description</p>
				{!editDescription &&
					<IconContainer onClick={() => {setEditDescription(true)}}>
						<EditIcon/>
					</IconContainer>
				}
			</DescriptionTitleContainer>
			{
				editDescription ? (
					<Formik
					initialValues={{ description: home.description}}
					onSubmit={()=>{}}
					>
						{props => (
							<FormContainer onSubmit={(e) => {e.preventDefault();}}>
								<InputDiv maxLength={'10000'} name={'description'} type='text' autoComplete="off" onChange={props.handleChange}
								value={props.values.description}/>
								<IconContainer onClick={() => {
									setEditDescription(false);
									updateHome(null, props.values.description, null);
								}}>
								<SaveIcon/>
							</IconContainer>
							</FormContainer>
					)}
					</Formik>
				) : (
					<>
						<LineSeparator/>
						<DescriptionContainer>{home.description}</DescriptionContainer>
						<LineSeparator/>

					</>
				)
			}
			<ImageContainer>
				<img src={home.path + home.filename} />
			</ImageContainer>
			<DropZoneContainer>
			<Dropzone
			accept={'image/jpeg, image/png, image/tiff, image/gif'}
			maxFiles={1}
			multiple={false}
			maxSize={31457280}
			onDropRejected={(rejectedFiles) => {}}
			onDropAccepted={(acceptedFiles) => {
				blobFile = new Blob(acceptedFiles);
				imgType = acceptedFiles[0].type.slice(6);
				var reader = new FileReader();
				reader.readAsDataURL(blobFile);
				reader.onloadend = () => {
					   var base64String = reader.result;
					   // uploadPhoto(blobFile);
					  updateHome(base64String, null, imgType);
			   }
			}}>
			  {({getRootProps, getInputProps}) => (
				<section>
				  <div {...getRootProps()}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop a file here, or click to select a file to change img</p>
				  </div>
				</section>
			  )}
			</Dropzone>
			</DropZoneContainer>

		</ProjectContainer>
	}
		</Container>
	);
};

export default AdminHome;
