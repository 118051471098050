import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
margin-left: 20px;
margin-right: 20px;
`;

export const DropZoneContainer = styled.div`
	margin-bottom: 10px;
	border-color: #eeeeee;
	border-style: dashed;
	border-width: 2px;
	background-color: #fafafa;
	color: #bdbdbd;
	div{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const AddPhotoDropZoneContainer = styled.div`
	margin-bottom: 10px;
	border-color: #eeeeee;
	border-style: dashed;
	border-width: 2px;
	background-color: #fafafa;
	color: #bdbdbd;
	width: 100%;
	max-width: 800px;
	align-self: center;
	div{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const EditTitleContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
`;

export const TitleContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

export const DescriptionTitleContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

export const ProjectContainer = styled.div`
	border: solid 1px #e0e0e0;
	border-radius: 5px;
	margin: auto;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	img {
		max-width: 400px;
		max-height: 400px;
	}
`;

export const ImageContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;

export const IconContainer = styled.div`
	margin-left: 10px;
	cursor: pointer;
	align-self: center;
`;

export const DescriptionContainer = styled.div`
	margin: auto;
`;


export const LineSeparator = styled.hr`
border-top: 1px solid #000000;
width: 100%;
`;

export const FormContainer = styled.form`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

export const InputDiv = styled.textarea`
	width: 800px;
	height: 200px;
	resize: none;
	::-webkit-scrollbar {
		width: 8px;
		background: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #a9a9a9;
	}
	:disabled {
		color: blue;
	}
`;
export const AllPhotoContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`;

export const PhotoContainer = styled.div`
	border: solid 1px #e0e0e0;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	padding: 10px;
	margin-left: 10px;
	margin-top: 10px;
	img{
		max-width: 250px;
		max-height: 250px;
	}
`;

export const AddPhotoTitleContainer = styled.div`
	margin-top: 20px;
	font-size: 20px;
	margin-left: auto;
	margin-right: auto;

`;
