import { Container, DropZoneContainer, InputDiv,  ProjectContainer,
IconContainer, TitleContainer, LineSeparator, ImageContainer, FormContainer,
DescriptionTitleContainer, PhotoContainer, AllPhotoContainer, AddPhotoTitleContainer,
DescriptionContainer, AddPhotoDropZoneContainer, EditTitleContainer} from './AdminProjectContent.style.jsx'
import Dropzone from 'react-dropzone'
import { useState, useEffect } from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Formik, Form, Field } from 'formik';
import EditIcon from './../../../assets/icons/EditIcon.js';
import SaveIcon from './../../../assets/icons/SaveIcon.js';
import DeleteIcon from './../../../assets/icons/DeleteIcon.js';

import * as Yup from 'yup';

let blobFile;
let imgType;

const AddProjectSchema = Yup.object().shape({
	'title': Yup.string().required(),
});

const AdminProjectContent = (props) => {
	const {uploadPhoto, project, requestProject, deletePhoto, updateProject} = props;
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [editTitle, setEditTitle] = useState<boolean>(false);
	const [editDescription, setEditDescription] = useState<boolean>(false);
	const [description, setDescription] = useState<string>('');


	const handleOnClick = (newTitle) => {
		updateProject(project.title, newTitle, null, null);
	};

	useEffect(() => {
		const projectTitle = props.match.params.id;
		requestProject(projectTitle);
	}, []);

	return (
		<Container>
		{project &&
		<ProjectContainer>
			<TitleContainer>
			{ editTitle ? (
				<Formik
				validationSchema={AddProjectSchema}
				initialValues={{ title: project.title}}
				onSubmit={()=>{}}
				>
					{props => (
						<EditTitleContainer >
							<input name={'title'} type='text' autoComplete="off" placeholder='Title' onChange={props.handleChange}
							value={props.values.title}/>
							<IconContainer onClick={() => {
								setEditTitle(false);
								handleOnClick(props.values.title)}}>
								<SaveIcon/>
							</IconContainer>
						</EditTitleContainer>
				)}
				</Formik>
			) : (
				<>
					<p>{project.title}</p>
					<IconContainer onClick={() => {setEditTitle(true)}}>
						<EditIcon/>
					</IconContainer>
				</>
			)}
			</TitleContainer>
			<LineSeparator/>
			<ImageContainer>
				<img src={project.path + project.filename} />
			</ImageContainer>
			<DropZoneContainer>
			<Dropzone
			accept={'image/jpeg, image/png, image/tiff, image/gif'}
			maxFiles={1}
			multiple={false}
			maxSize={31457280}
			onDropRejected={(rejectedFiles) => {}}
			onDropAccepted={(acceptedFiles) => {
				blobFile = new Blob(acceptedFiles);
				imgType = acceptedFiles[0].type.slice(6);
				var reader = new FileReader();
		  		reader.readAsDataURL(blobFile);
				reader.onloadend = () => {
					   var base64String = reader.result;
					   // uploadPhoto(blobFile);
					   updateProject(project.title, null, base64String, null, imgType);
			   }
			}}>
			  {({getRootProps, getInputProps}) => (
				<section>
				  <div {...getRootProps()}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop a file here, or click to select a file to change img</p>
				  </div>
				</section>
			  )}
			</Dropzone>
			</DropZoneContainer>
			<LineSeparator/>
				<DescriptionTitleContainer>
					<p>Description</p>
					{!editDescription &&
						<IconContainer onClick={() => {setEditDescription(true)}}>
							<EditIcon/>
						</IconContainer>
					}
				</DescriptionTitleContainer>

			{
				editDescription ? (

				<Formik
				initialValues={{ description: project.description}}
				onSubmit={()=>{}}
				>
					{props => (
						<FormContainer onSubmit={(e) => {e.preventDefault();}}>
							<InputDiv maxLength={'10000'} name={'description'} type='text' autoComplete="off" onChange={props.handleChange}
							value={props.values.description}/>
							<IconContainer onClick={() => {
								setEditDescription(false);
								updateProject(project.title, null, null, props.values.description, null);
							}}>
							<SaveIcon/>
						</IconContainer>
						</FormContainer>
				)}
				</Formik>
			) : (
				<>
				<LineSeparator/>
				<DescriptionContainer>{project.description}</DescriptionContainer>
				</>
			)
			}
		</ProjectContainer>
	}
	<AddPhotoTitleContainer>
		Add photos to project
	</AddPhotoTitleContainer>
		<AddPhotoDropZoneContainer>
		<Dropzone
		accept={'image/jpeg, image/png, image/tiff, image/gif'}
		maxFiles={1}
		multiple={false}
		maxSize={31457280}
		onDropRejected={(rejectedFiles) => {}}
		onDropAccepted={(acceptedFiles) => {
			blobFile = new Blob(acceptedFiles);
			imgType = acceptedFiles[0].type.slice(6);
			var reader = new FileReader();
	  		reader.readAsDataURL(blobFile);
			reader.onloadend = () => {
				   var base64String = reader.result;
				   if (project) {
					   uploadPhoto(project.title, base64String, imgType);
				   }
		   }
		}}>
		  {({getRootProps, getInputProps}) => (
			<section>
			  <div {...getRootProps()}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
			  </div>
			</section>
		  )}
		</Dropzone>
		</AddPhotoDropZoneContainer>
		<AllPhotoContainer>
	   {project && project.photos && project.photos.map((item, i) => {
		   return (
			   <PhotoContainer key={i} >
				   <img src={item.path + item.filename}  />
				   <IconContainer onClick={() => {deletePhoto(project.title, item.filename)}}>
					   <DeleteIcon/>
				   </IconContainer>
			   </PhotoContainer>
		   )
	   })}
	   </AllPhotoContainer>

		</Container>
	);
};

export default AdminProjectContent;
