import React from 'react';
const DeleteIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
	<path d="M0 0h24v24H0z" fill="none"/>
	<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
	</svg>

);

DeleteIcon.defaultProps = {
	width: 24,
	height: 24,
}

export default DeleteIcon;
