import { Container, ProjectCardViewContainer} from './AdminProjectsPage.style.jsx'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Link} from "react-router-dom";
import { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {ProjectCardView} from '../ProjectCardView'
import {AddProjectForm} from '../AddProjectForm'
import Dropzone from 'react-dropzone'

const AddProjectSchema = Yup.object().shape({
	'title': Yup.string().required(),
});

const AdminProjectsPage = (props) => {
	const {deleteProject, requestProjects, projects} = props;

	useEffect(() => {
		requestProjects();
	}, []);

	const handleDeleteProject = (event, title) => {
		event.preventDefault();
		deleteProject(title);
	}
	return (
		<Container>
			<Formik
			validationSchema={AddProjectSchema}
			initialValues={{ title: ''}}
			onSubmit={()=>{}}
			>
				{({...formikProps}) => (
				<AddProjectForm {...formikProps} />
			)}
			</Formik>

			<ProjectCardViewContainer>
					{projects.map((project, i) => {
						return (
							<ProjectCardView key={i} project={project} deleteProject={(event) => {
								handleDeleteProject(event, project.title)}
							}/>
						)
					})}

			</ProjectCardViewContainer>
		</Container>
	);
};

export default AdminProjectsPage;
