import { call, put, takeLatest } from "redux-saga/effects";
import {REQUEST_ABOUT, UPDATE_ABOUT} from './constant.js';
import {requestAboutSuccess, updateAboutSuccess} from './action.js'
import { queryGet, queryPut } from "../Api.js";
import {replace} from 'connected-react-router';
import {STORAGE_KEY, PATH_LOGIN_REPLACE} from '../constant.js';

const PATH_ABOUT = "about";

export function* requestAboutSaga() {
	try {
		const response = yield call(queryGet, PATH_ABOUT);
		yield put(requestAboutSuccess(response.data));
	} catch(e) {
	}

}

export function* updateAboutSaga(action) {
	try {
		const token = sessionStorage.getItem(STORAGE_KEY);
		if (!token){
			yield put(replace(PATH_LOGIN_REPLACE));
			return;
		}
		const description = action.payload.description;
		const response = yield call(queryPut, PATH_ABOUT, {description, token});
		yield put(updateAboutSuccess(response.data));
	} catch(e) {
		if (e === 401) {
			sessionStorage.removeItem(STORAGE_KEY);
			yield put(replace(PATH_LOGIN_REPLACE));
		}
	}
}


export function* aboutSaga() {
  yield takeLatest(REQUEST_ABOUT, requestAboutSaga);
  yield takeLatest(UPDATE_ABOUT, updateAboutSaga);
}
