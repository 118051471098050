import {REQUEST_PROJECTS, REQUEST_PROJECTS_SUCCESS, REQUEST_PROJECTS_FAILURE,
UPDATE_PROJECT, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE,
REQUEST_PROJECT, REQUEST_PROJECT_SUCCESS, REQUEST_PROJECT_FAILURE,
DELETE_PROJECT, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE,
CREATE_PROJECT, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE,
UPLOAD_PHOTO, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE,
DELETE_PHOTO, DELETE_PHOTO_SUCCESS} from './constant.js';

export const requestProjects = () => ({
  type: REQUEST_PROJECTS,
});

export const requestProjectsSuccess = (projects) => ({
   type: REQUEST_PROJECTS_SUCCESS,
   payload: {projects}
});

export const requestProjectsFailure = () => ({
   type: REQUEST_PROJECTS_FAILURE,
});


export const updateProject = (title, newTitle, base64String, description, imgType) => ({
  type: UPDATE_PROJECT,
  payload: {title, newTitle, base64String, description, imgType}
});

export const updateProjectSuccess = project => ({
   type: UPDATE_PROJECT_SUCCESS,
   payload: {project}
});

export const updateProjectFailure = () => ({
   type: UPDATE_PROJECT_FAILURE,
});

export const deleteProject = title => ({
  type: DELETE_PROJECT,
  payload: {title}
});

export const deleteProjectSuccess = allProjects => ({
   type: DELETE_PROJECT_SUCCESS,
   payload: {allProjects}
});

export const deleteProjectFailure = () => ({
   type: DELETE_PROJECT_FAILURE,
});

export const createProject = (allProjects, title, base64String, imgType) => ({
  type: CREATE_PROJECT,
  payload: {allProjects, title, base64String, imgType}
});

export const createProjectSuccess = allProjects => ({
   type: CREATE_PROJECT_SUCCESS,
   payload: {allProjects}
});

export const createProjectFailure = () => ({
   type: CREATE_PROJECT_FAILURE,
});

export const uploadPhoto = (projectTitle, base64String, imgType) => ({
	type: UPLOAD_PHOTO,
	payload: {projectTitle, base64String, imgType}
});

export const deletePhoto = (projectTitle, filename) => ({
	type: DELETE_PHOTO,
	payload: {projectTitle, filename}
});

export const uploadPhotoSuccess = project => ({
	type: UPLOAD_PHOTO_SUCCESS,
	payload: {project}
});

export const deletePhotoSuccess = project => ({
	type: DELETE_PHOTO_SUCCESS,
	payload: {project}
});

export const requestProject = projectTitle => ({
  type: REQUEST_PROJECT,
  payload: {projectTitle}
});

export const requestProjectSuccess = (projectResponse) => ({
   type: REQUEST_PROJECT_SUCCESS,
   payload: {projectResponse}
});

export const requestProjectFailure = () => ({
   type: REQUEST_PROJECT_FAILURE,
});
